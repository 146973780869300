import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footerContainer">
      <div className="footerBrand"></div>
      {/* <div>Terms of Service</div> */}
    </div>
  );
}

export default Footer;
