import React from "react";
import "./ExampleCards.css";
import { dummyExamples } from "./dummyData";
import aron from "../../../assets/aron.jpg";
import ig from "../../../assets/instagram.png";
import statArrow from "../../../assets/arrow-up-solid.svg";

function ExampleCards() {
  return (
    <div className="ExampleCardContainer">
      <div className="userInfoContainer">
        <img src={aron} alt="profile picture" />
        <div>
          <a
            href="https://www.instagram.com/ar0nsadventures"
            target="_blank"
            className="userInfo"
          >
            <span>Aron</span>
            <div className="instagram">
              <img src={ig} alt="" />
              <span>@ar0nsadventures</span>
            </div>
          </a>
          <div className="userStats">
            <div className="stats">
              <img src={statArrow} alt="statistic arrow" />
              <div className="statNumbers">
                <span>3M+</span>
                <span>Views</span>
              </div>
            </div>
            <div className="stats">
              <img src={statArrow} alt="statistic arrow" />
              <div className="statNumbers">
                <span>100k+</span>
                <span>Accounts Reached Monthly</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="aronsMessage">
        "Hey there, I'm Aron, the one kickstarting things at Impressify. My
        journey in social media started with my food/travel page. Over time, I
        noticed how influencers were becoming more like advertisers. Every post
        is just another ad, lacking authenticity and that personal touch. At
        Impressify, we believe in creating in house content about your business
        that resonates with your audience on a personal level."
      </div>
    </div>
  );
}

export default ExampleCards;
