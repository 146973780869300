import { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import aron from "../../assets/aron.jpg";
import hongdae from "../../assets/hongdae.jpg";
import piggysIsland from "../../assets/piggys-island.jpg";
import hoseki from "../../assets/hoseki.jpg";
import ryuIzakaya from "../../assets/ryu-izakaya.jpg";
import yumyumchicken from "../../assets/yumyumchicken.jpg";
import bbqchicken from "../../assets/bbq-chicken.jpg";
import tycoonTofu from "../../assets/tycoon-tofu.jpg";
import bombayFrankies from "../../assets/bombay-frankies.jpg"
import hutaoli from "../../assets/hutaoli.jpg"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.css";

function TestCarousel() {
  let sliderRef = useRef(null);

  const [centerIndex, setCenterIndex] = useState(0);
  const [centerMode, setCenterMode] = useState();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 430) {
        setCenterMode(false);
      } else {
        setCenterMode(true);
      }
    };

    // Set initial state based on current window size
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  const CustomNextArrow = () => (
    <button
      className={`arrow-button next-arrow ${
        !centerMode && "phone-screen-next"
      }`}
      onClick={next}
    >
      <i className="fa-solid fa-chevron-right service-slider-arrow-icon" />
    </button>
  );

  const CustomPrevArrow = () => (
    <button
      className={`arrow-button prev-arrow ${
        !centerMode && "phone-screen-prev"
      }`}
      onClick={previous}
    >
      <i className="fa-solid fa-chevron-left service-slider-arrow-icon" />
    </button>
  );

  const handleBeforeChange = (oldIndex, newIndex) => {
    setCenterIndex(newIndex);
  };

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    centerMode: centerMode,
    beforeChange: (oldIndex, newIndex) =>
      handleBeforeChange(oldIndex, newIndex),
    slidesToShow: 5, // Number of slides to show at a time
    slidesToScroll: 1,
    swipeToSlide: true,
    // nextArrow: <CustomNextArrow />,
    // prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1650,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const content = [
    {
      restaurantName: "Hongdae Korean Restaurant",
      restaurantImage: hongdae,
      videoLink:
        "https://player.vimeo.com/video/972490484?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
      restaurantName: "Hōseki Sushi",
      restaurantImage: hoseki,
      videoLink:
        "https://player.vimeo.com/video/972420681?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
      restaurantName: "Piggy's Island",
      restaurantImage: piggysIsland,
      videoLink:
        "https://player.vimeo.com/video/972382813?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
      restaurantName: "Ryu Izakaya",
      restaurantImage: ryuIzakaya,
      videoLink:
        "https://player.vimeo.com/video/972501846?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
      restaurantName: "Tycoon Tofu",
      restaurantImage: tycoonTofu,
      videoLink:
        "https://player.vimeo.com/video/979377631?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
      restaurantName: "Bombay Frankies",
      restaurantImage: bombayFrankies,
      videoLink:
        "https://player.vimeo.com/video/993159463?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
      restaurantName: "BBQ Chicken",
      restaurantImage: bbqchicken,
      videoLink:
        "https://player.vimeo.com/video/979377750?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
      restaurantName: "YumYum Chicken",
      restaurantImage: yumyumchicken,
      videoLink:
        "https://player.vimeo.com/video/979377523?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
      restaurantName: "Hutaoli",
      restaurantImage: hutaoli,
      videoLink:
        "https://player.vimeo.com/video/993159487?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
  ];

  return (
    <div className="carouselContainer">
      <div className="statsHeader">Our Videos Get Noticed!</div>
      <Slider
        {...settings}
        ref={(slider) => {
          sliderRef = slider;
        }}
      >
        {content.map((restaurant, index) => (
          <div
            className={` slide-container  ${
              index !== centerIndex ? "not-center-slide" : "center-slide"
            }`}
          >
            <iframe
              title={restaurant.restaurantName}
              src={restaurant.videoLink}
              frameborder="0"
              className="video-player"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            ></iframe>
            <div className="testimonial-user-info">
              <img
                src={restaurant.restaurantImage}
                alt=""
                className="testimonial-user-icon"
              />
              <span>{restaurant.restaurantName}</span>
            </div>
            {index === centerIndex && (
              <>
                <CustomNextArrow />
                <CustomPrevArrow />
              </>
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default TestCarousel;
