import { useState, useEffect } from "react";
import "./AttentionHook.css";
import UseFadeInOnScroll from "../../hooks/UseFadeInOnScroll";

function AttentionHook() {
  const [animationOne, setAnimationOne] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAnimationOne(true);
    }, 800);
  }, []);

  return (
    <div className="attentionHookContainer">
      <h1 className="bounceAnimation">Our Content Leaves a Lasting </h1>
      <h1 className="bounceAnimation"> Impression Beyond the Screen</h1>
      <div
        className={`hookDescription fadein ${animationOne && "hookVisible"}`}
      >
        Your Brand, Our Expertise. Leave the social scene to us, we got that
        covered.
      </div>
      <a className="buttonContainer" href="#pricing">
        <div>Get Started</div>
      </a>
    </div>
  );
}

export default AttentionHook;
