import React from "react";
import "./BeforePricing.css";

function BeforePricing() {
  return (
    <div className="cardContainer">
      <h1>Use our world class video talent to grow faster.</h1>
      <span>No job posts. No managing employees. No headaches</span>
      <div className="steps">
        <div className="step">
          <span>1</span>
          <span>Subscribe and Share</span>
        </div>
        <div className="step">
          <span>2</span>
          <span>Get amazing content</span>
        </div>
        <div className="step">
          <span>3</span>
          <span>Publish & Grow</span>
        </div>
      </div>
    </div>
  );
}

export default BeforePricing;
