import React from "react";
import circle from "../../assets/circle-plus-solid.svg";
import yes from "../../assets/yes.png";
import "./ExtraInfo.css";
import UseFadeInOnScroll from "../../hooks/UseFadeInOnScroll";

function ExtraInfo() {
  const { ref, isVisible } = UseFadeInOnScroll();

  const data = [
    {
      id: 1,
      title: "Book a free strategy call",
      action: "Get Started",
      message: "Learn about how we can help you grow your audience",
      image:
        "https://assets-global.website-files.com/6511c8a57f9b11ef18e4c75c/65198fc2af4f709c4a1cdd38_call.png",
    },
    {
      id: 2,
      title: "Refer a friend and get paid",
      action: "Join Waitlist",
      message: "Earn 5% recurring commissions for each referral.",
      image:
        "https://assets-global.website-files.com/6511c8a57f9b11ef18e4c75c/65198fc269305abca20b3bb7_644ec2bd57472f7674feef2f_referral.png",
    },
  ];

  const rightFitData = [
    {
      id: 1,
      backgroundColor: "white",
      title: `Does this sound like you?`,
      message: [
        `You aim to create genuine connections within the community.`,
        `You prioritize engaging content over extravagant video production.`,
        `You believe that cultivating an online presence requires a long-term commitment.`,
        `You seek an efficient process for generating content for your social media platforms.`,
      ],
    },
  ];

  return (
    <div className={`extraInfoContainer  ${isVisible && "visible"}`} ref={ref}>
      <div className="extraInfo">
        <div className="extraInfoHeader">
          <div>Does this sound like you?</div>
          <span className="extraInfoBlurb">
            We want to make sure that we're a good fit
          </span>
          <a
            className="book"
            href="https://xjk68s3sjmh.typeform.com/to/gTUXzC68"
            target="_blank"
          >
            Book a call
          </a>
        </div>
        {rightFitData.map((data, index) => (
          <div key={index} className="extraInfoContent">
            {data.message.map((message, index) => (
              <div key={index} className=" bold infoMessage ">
                <img src={yes} alt="" />
                <span>{message}</span>
                <div id={index === 3 && "faq"}></div>
              </div>
            ))}
          </div>
        ))}
      </div>
      {/* <div id="faq"></div> */}
    </div>
  );
}

export default ExtraInfo;
