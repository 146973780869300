import { useState } from "react";
import "./Navbar.css";
import hamburger from "../../assets/hamburger.png";

function Navbar() {
  const [display, setDisplay] = useState(false);

  return (
    <div className="navbarContainer">
      <div className="navLeft">
        <a href="/">
          <span>Impressify</span>
        </a>
      </div>
      <div className="navRight">
        <a href="#howitworks">
          <span>How It Works</span>
        </a>
        <a href="#pricing">
          <span>Pricing</span>
        </a>
        <a href="#faq">
          <span>FAQ</span>
        </a>
      </div>
      <div className="navRightHamburger">
        <button className="navRightBurger" onClick={() => setDisplay(!display)}>
          <img src={hamburger} alt="" />
        </button>
        {display && (
          <div className="dropdownMenu">
            <a href="#howitworks" onClick={() => setDisplay(false)}>
              <span>How It Works</span>
            </a>
            <a href="#pricing" onClick={() => setDisplay(false)}>
              <span>Pricing</span>
            </a>
            <a href="#faq" onClick={() => setDisplay(false)}>
              <span>FAQ</span>
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;
