import React from "react";
import "./HowItWorks.css";
import ContentCards from "./ContentCards/ContentCards";
import Persuasion from "./Persuasion/Persuasion";
import { contentData } from "./ContentCards/ContentCardsData";
import { persuasionData } from "./Persuasion/PersuasionData";
import { contentPlatformData } from "./ContentPlatforms/ContentPlatformData";
import ContentPlatforms from "./ContentPlatforms/ContentPlatforms";
import UseFadeInOnScroll from "../../hooks/UseFadeInOnScroll";

function HowItWorks() {
  const { ref, isVisible } = UseFadeInOnScroll();

  return (
    <div className="worksContainer">
      <div className="worksHook">
        Let us make you stand out in the feed. Here's how we operate
      </div>
      <div
        className={`contentCardsContainer ${isVisible && "visible"}`}
        ref={ref}
      >
        {contentData.map((content, index) => (
          <>
            <ContentCards content={content} index={index} />
          </>
        ))}
      </div>
      {/* <div className='persuasionContentContainer'>
        {persuasionData.map((content, index) => (
          <Persuasion content={content} index={index} />
        ))}
      </div> */}
      {/* <div className='contentPlatformContainer'>
        {contentPlatformData.map((content, index) => (
          <ContentPlatforms content={content} index={index} />
        ))}
      </div> */}
    </div>
  );
}

export default HowItWorks;
