import UseFadeInOnScroll from "../../hooks/UseFadeInOnScroll";
import "./Statistics.css";

function Statistics() {
  const { ref, isVisible } = UseFadeInOnScroll();

  return (
    <div className={`statsContainer`}>
      <div className="statsHeader">
        Social Media Impressions Are Undeniable!
      </div>
      <div className={`statistics ${isVisible && "visible"}`} ref={ref}>
        <div className="statistic">
          <div>~3 Hours</div>
          <div>Spent daily on social media on average</div>
        </div>
        <div className="statistic">
          <div>89%</div>
          <div>
            Percentage of marketers that believe social media marketing has
            increased their brand's exposure
          </div>
        </div>
        <div className="statistic">
          <div>$219.8B</div>
          <div>
            Projected spending in the Social Media Advertising market in 2024
          </div>
        </div>
      </div>
    </div>
  );
}

export default Statistics;
