export const pricingData = [
  {
    planType: 'Essential',
    cost: '$1000/mo',
    jobService: '3 videos filmed and edited',
  },
  {
    planType: 'Premium',
    cost: '$1300/mo',
    jobService: '6 videos filmed and edited',
  },
  {
    planType: 'Elite',
    cost: '$2000/mo',
    jobService: '10 videos filmed and edited',
  }
]

export const services = ['Filming','Script creation', 'Video editing']
export const services2 = ['Actor casting', 'Chat directly with team', 'Pause or cancel anytime']
