import React from "react";
import "./ContentCards.css";

function ContentCards({ content, index }) {
  return (
    <div
      key={index}
      className="contentCardContainer"
      style={{ backgroundColor: content.color }}
    >
      <span className="contentTitle">{content.title}</span>
      <img src={content.image} alt="" />
      <span className="contentMessage">{content.message}</span>
      {index === 2 && <div id={index === 2 && "pricing"}></div>}
    </div>
  );
}

export default ContentCards;
