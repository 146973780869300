import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import AttentionHook from './components/AttentionHook/AttentionHook';
import ExampleCards from './components/Examples/ExampleCards/ExampleCards';
import HowItWorks from './components/HowItWorks/HowItWorks';
import BeforePricing from './components/BeforePricing/BeforePricing';
import Pricing from './components/Pricing/Pricing';
import ExtraInfo from './components/ExtraInfo/ExtraInfo';
import Footer from './components/Footer/Footer';
import Questions from './components/Questions/Questions';
import Statistics from './components/Statistics/Statistics';
import Comparison from './components/Comparison/Comparison';
import Carousel from './components/Carousel/Carousel';
import TestCarousel from './components/Carousel/TestCarousel';

function App() {
  return (
    <div className="App">
      <Navbar />
      <AttentionHook />
      <ExampleCards />
      <Carousel />
      <Statistics />
      <Comparison />
      <HowItWorks />
      {/* <BeforePricing /> */}
      <Pricing />
      <ExtraInfo />
      <Questions />
      <Footer />
    </div>
  );
}

export default App;
