import { useState } from "react";
import "./Questions.css";
import circle from "../../assets/circle-plus-solid.svg";
import minus from "../../assets/circle-xmark-solid.svg";

function Questions() {
  const data = [
    {
      id: 1,
      questions: "Do I get to keep the videos?",
      answer: `Upon your satisfaction with the video, we'll promptly deliver a copy to you, granting you complete ownership of the content.`,
    },
    {
      id: 2,
      questions: "What if I’m not happy with a video?",
      answer: `We ensure your involvement throughout the entire video creation process, from script conception to meticulous editing. We'll iterate until the final product exceeds your expectations.`,
    },
    {
      id: 3,
      questions: "Are the prices negotiable?",
      answer: `Regrettably, our pricing remains highly competitive. Compared to other agencies charging nearly 5x our rates, we strive to offer exceptional value.`,
    },
    {
      id: 4,
      questions: "Why don’t I just hire my own content creation team?",
      answer: `When you partner with us, you gain access to a skilled team of content creators. Employing a full-time content team could incur annual costs exceeding $100k. Even with our highest-tier plan, your investment is a fraction of that expense.`,
    },
    {
      id: 5,
      questions: "Why not just hire in-house creators?",
      answer: `We let you scale fast with instant access to the best video talent. Plus the flexibility to scale up or down as needed.
      Good luck finding a great content creator in the US or Canada. If they're good at what they do, they're most likely influencers and will cost you $100,000+
      We give you the world's best talent overnight for a fraction of the price.`,
    },
    {
      id: 6,
      questions: "How do I get started?",
      answer: `Complete our onboarding form, and one of our team members will promptly reach out to you.`,
    },
    {
      id: 7,
      questions: "Can I cancel whenever I want?",
      answer: `While it's challenging to envision why you'd consider canceling after witnessing the results, rest assured, you have the flexibility to do so. We operate without long-term contracts or hidden fees.`,
    },
  ];

  const [open, setOpen] = useState(null);

  const toggleButton = (id) => {
    if (open === id) {
      setOpen(null);
    } else {
      setOpen(id);
    }
  };

  return (
    <div className="container">
      <div className="faqWrapper">
        <div className="layoutGrid">
          <div className="faqLeft">
            <span className="title">FAQ</span>
          </div>
          <div>
            <div className="faqContainer">
              <div className={`faq ${open === 1 ? "open1" : "close"}`}>
                <div className="faqToggle" onClick={() => toggleButton(1)}>
                  <div>Do I get to keep the videos?</div>
                  <div className="faqPlus">
                    <img src={open === 1 ? minus : circle} alt="" />
                  </div>
                </div>
                <p className="faqAnswer">
                  Upon your satisfaction with the video, we'll promptly deliver
                  a copy to you, granting you complete ownership of the content.
                </p>
              </div>
              <div className={`faq ${open === 2 ? "open2" : "close"}`}>
                <div className="faqToggle" onClick={() => toggleButton(2)}>
                  <div>What if I’m not happy with a video?</div>
                  <div className="faqPlus">
                    <img src={open === 2 ? minus : circle} alt="" />
                  </div>
                </div>
                <p className="faqAnswer">
                  We ensure your involvement throughout the entire video
                  creation process, from script conception to meticulous
                  editing. We'll iterate until the final product exceeds your
                  expectations.
                </p>
              </div>
              <div className={`faq ${open === 3 ? "open2" : "close"}`}>
                <div className="faqToggle" onClick={() => toggleButton(3)}>
                  <div>Are the prices negotiable?</div>
                  <div className="faqPlus">
                    <img src={open === 3 ? minus : circle} alt="" />
                  </div>
                </div>
                <p className="faqAnswer">
                  Regrettably, our pricing remains highly competitive. Compared
                  to other agencies charging nearly 5x our rates, we strive to
                  offer exceptional value.
                </p>
              </div>
              <div className={`faq ${open === 4 ? "open4" : "close"}`}>
                <div className="faqToggle" onClick={() => toggleButton(4)}>
                  <div>Can't I hire my own content team?</div>
                  <div className="faqPlus">
                    <img src={open === 4 ? minus : circle} alt="" />
                  </div>
                </div>
                <p className="faqAnswer">
                  When you partner with us, you gain access to a skilled team of
                  content creators. Employing a full-time content team could
                  incur annual costs exceeding $100k. Even with our highest-tier
                  plan, your investment is a fraction of that expense.
                </p>
              </div>
              <div className={`faq ${open === 6 ? "open1" : "close"}`}>
                <div className="faqToggle" onClick={() => toggleButton(6)}>
                  <div>How do I get started?</div>
                  <div className="faqPlus">
                    <img src={open === 6 ? minus : circle} alt="" />
                  </div>
                </div>
                <p className="faqAnswer">
                  Complete our{" "}
                  <a
                    href="https://xjk68s3sjmh.typeform.com/to/gTUXzC68"
                    target="_blank"
                    className="faqRedirect"
                  >
                    onboarding form
                  </a>{" "}
                  , and one of our team members will promptly reach out to you.
                </p>
              </div>
              <div className={`faq ${open === 7 ? "open2" : "close"}`}>
                <div className="faqToggle" onClick={() => toggleButton(7)}>
                  <div>Can I cancel whenever I want?</div>
                  <div className="faqPlus">
                    <img src={open === 7 ? minus : circle} alt="" />
                  </div>
                </div>
                <p className="faqAnswer">
                  While it's challenging to envision why you'd consider
                  canceling after witnessing the results, rest assured, you have
                  the flexibility to do so. We operate without long-term
                  contracts or hidden fees.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Questions;
