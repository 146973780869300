import filming from '../../../assets/filming.jpeg'
import packageImage from '../../../assets/package.jpg'
import brainstorming from '../../../assets/brainstorming.png'

export const contentData = [
  {
    title: 'Ideate',
    image: brainstorming,
    message: `We invest time in understanding your business, allowing us to curate content that aligns seamlessly with your brand identity.
    `,
    color: 'white'
  },
  {
    title: 'Implement',
    image: filming,
    message: `Once the content type is determined to match your brand, we handle every aspect, from script creation to filming and meticulous editing.
    `,
    color: 'white'
  },
  {
    title: 'Impress',
    image: packageImage,
    message: `You'll receive the final product for review. We welcome any revisions to ensure it meets your expectations before we proceed to showcase it, leaving a lasting impression with your audience.
    `,
    color: 'white'
  },
] 