import React from "react";
import "./Persuasion.css";

function Persuasion({ content, index }) {
  return (
    <div key={index} className='persuasioncardContainer'>
      <img src={content.image} alt='' />
      <h3>{content.title}</h3>
      <span>{content.message}</span>
    </div>
  );
}

export default Persuasion;
