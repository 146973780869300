import React from "react";
import "./Pricing.css";
import { pricingData, services, services2 } from "./data";
import yes from "../../assets/yes.png";
import UseFadeInOnScroll from "../../hooks/UseFadeInOnScroll";

function Pricing() {
  const { ref, isVisible } = UseFadeInOnScroll();

  return (
    <div className={`price  `}>
      <div className="pricingInformation">
        <div>Pricing</div>
        <div
          className={`informationContainer ${
            isVisible ? "visible" : "notvisible"
          }`}
          ref={ref}
        >
          <text>
            All our plans offer the same awesome services. You simply pick how
            many videos you want each month!
          </text>
          <div className={`serviceOffered`}>
            <div>
              {services.map((service, index) => (
                <div key={index} className="service">
                  <img src={yes} alt="" />
                  <span>{service}</span>
                </div>
              ))}
            </div>
            <div>
              {services2.map((service, index) => (
                <div key={index} className="service">
                  <img src={yes} alt="" />
                  <span>{service}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={`pricingContainer  ${isVisible && "visible"}`} ref={ref}>
        {pricingData.map((data, index) => (
          <div
            key={index}
            className={`plans ${data.planType === "Elite" && "mostPopular"}`}
          >
            <span>{data.planType}</span>
            <span>{data.cost}</span>
            <span>{data.jobService}</span>
            <a
              className="servicebutton"
              href="https://xjk68s3sjmh.typeform.com/to/gTUXzC68"
              target="_blank"
            >
              Book A Call
            </a>
            {data.planType === "Elite" && (
              <div className="maximumBadge">BEST VALUE</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Pricing;
