import React from "react";
import "./Comparison.css";
import { comparisonData } from "./data";
import yes from "../../assets/yes.png";
import no from "../../assets/cross.png";
import UseFadeInOnScroll from "../../hooks/UseFadeInOnScroll";

function Comparison() {
  const { ref, isVisible } = UseFadeInOnScroll();

  return (
    <div className="comparison">
      <div className="comparisonHeader">In-House Content Is A Clear Winner</div>
      <div
        className={`comparisonContainer ${isVisible && "visible"}`}
        ref={ref}
      >
        <div className="comparisonDemonstration">
          <div>Influencer Marketing</div>
          <div className="comparisonList">
            {comparisonData.influencer.map((data, index) => (
              <div className="comparisonData">
                <img src={no} alt="" className="iconSize" />
                <span key={index}>{data}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="comparisonDemonstration">
          <div>In-House Content</div>
          <div className="comparisonList">
            {comparisonData.inHouse.map((data, index) => (
              <div className="comparisonData">
                <img src={yes} alt="" className="iconSize" />
                <span key={index}>{data}</span>
                <div id={index === 3 && "howitworks"}></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Comparison;
